<template>
    <main class="flex-1 overflow-y-auto h-full">
        <PageHead title="Angebote" sticky>
            <template #actions>
                <c-button :path="{ name: 'OfferAdd' }">Neu</c-button>
            </template>
        </PageHead>

        <List :items="offers"
              editRouteName="OfferEdit"/>

        <p class="p-6 text-center text-base text-gray-400">
            {{ offers.length }} Angebote wurden geladen.
        </p>

    </main>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'Offers',
    computed: {
        ...mapState('products', ['offers']),
    },
};
</script>
